import { render, staticRenderFns } from "./CustomPageCreator.vue?vue&type=template&id=35ca93ef&scoped=true&"
import script from "./CustomPageCreator.vue?vue&type=script&lang=js&"
export * from "./CustomPageCreator.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35ca93ef",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardActions,VCardText,VChip,VCol,VDivider,VSpacer})
