var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-n1",attrs:{"outlined":""}},[_c('ConfirmationDialog',{ref:"deleteTokenDialog",attrs:{"title":_vm.$t('user.confirm-link-deletion'),"message":_vm.$t('user.are-you-sure-you-want-to-delete-the-link', {
        link: _vm.activeName,
      }),"icon":_vm.$globals.icons.alert,"width":450},on:{"confirm":_vm.deleteToken,"close":_vm.closeDelete}}),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-icon',{staticClass:"mr-1",attrs:{"large":"","color":"accent"}},[_vm._v(" "+_vm._s(_vm.$globals.icons.externalLink)+" ")]),_c('v-toolbar-title',{staticClass:"headine"},[_vm._v(" "+_vm._s(_vm.$t("signup.sign-up-links"))+" ")]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"success","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("user.create-link"))+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-app-bar',{attrs:{"dark":"","dense":"","color":"primary"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.$globals.icons.user)+" ")]),_c('v-toolbar-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t("user.create-link"))+" ")]),_c('v-spacer')],1),_c('v-form',{ref:"newUser",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$t('user.link-name'),"rules":[_vm.existsRule],"validate-on-blur":""},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}}),_c('v-checkbox',{attrs:{"label":_vm.$t('user.admin')},model:{value:(_vm.editedItem.admin),callback:function ($$v) {_vm.$set(_vm.editedItem, "admin", $$v)},expression:"editedItem.admin"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("general.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("general.save"))+" ")])],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.links,"sort-by":"calories"},scopedSlots:_vm._u([{key:"item.token",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.baseURL + "/sign-up/" + (item.token)))+" "),_c('TheCopyButton',{attrs:{"copy-text":(_vm.baseURL + "/sign-up/" + (item.token))}})]}},{key:"item.admin",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":item.admin ? 'success' : 'error',"text":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.$globals.icons.admin)+" ")]),_vm._v(" "+_vm._s(item.admin ? _vm.$t("general.yes") : _vm.$t("general.no"))+" ")],1)]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.$globals.icons.delete)+" ")]),_vm._v(" "+_vm._s(_vm.$t("general.delete"))+" ")],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }