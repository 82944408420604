<template>
  <v-container fluid class="pa-5">
    <v-slide-x-transition hide-on-leave>
      <router-view></router-view>
    </v-slide-x-transition>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
