var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 mt-2",attrs:{"dense":"","headers":_vm.dataHeaders,"items":_vm.dataSet,"item-key":"name","show-expand":"","expanded":_vm.expanded,"footer-props":{
      'items-per-page-options': [100, 200, 300, 400, -1],
    },"items-per-page":100},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('div',{class:item.status ? 'success--text' : 'error--text'},[_vm._v(" "+_vm._s(item.status ? "Imported" : "Failed")+" ")])]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"ma-2"},[_vm._v(" "+_vm._s(item.exception)+" ")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }